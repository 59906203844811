
















































































































import BaseLink from "../../Base/BaseLink.vue";
import BaseAvatar from "../../Base/BaseAvatar.vue";
import VueApexCharts from "vue-apexcharts";
import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: {
        VueApexCharts,
        BaseCard,
        BaseAvatar,
        BaseLink,
    },
})
export default class SharedDashboardCharts extends Vue {
    @Prop() funds!: any[];
    @Prop() statistics!: any[];
    @Prop() topCompanies!: any[];
    @Prop() selectedYear!: number;

    public isChartLoaded: boolean = false;

    get year() {
        return this.selectedYear;
    }
    set year(newValue: number) {
        this.$emit("year:update", newValue);
    }

    public options: any = {
        chart: {
            id: "given-fund-chart",
        },
        xaxis: {
            type: "datetime",
            labels: {
                formatter: function (value: number) {
                    const date = new Date(value);
                    const month = date.toLocaleString("id-ID", {
                        month: "long",
                        year: "numeric",
                    });
                    return month;
                },
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: number) {
                    return value
                        .toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                        })
                        .slice(0, -3);
                },
            },
        },
    };

    public series: any[] = [];

    public donutLabels: any[] = [
        { text: "Rencana Dana CSR" },
        { text: "Dana Yang Telah Diberikan" },
    ];

    public donutOptions: any = {
        chart: {
            type: "pie",
        },
        labels: ["Sisa Dana", "Dana Diberikan"],
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: 0,
            containerMargin: {
                top: 30,
                bottom: 30,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: number) {
                    return value
                        .toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                        })
                        .slice(0, -3);
                },
            },
        },
    };
    public donutSeries: any[] = [];

    initialName(name: string) {
        let initialName = name.charAt(0);

        return initialName;
    }

    get monthsToArray() {
        const months = [...Array(12)].map((_, i) => {
            const year = this.selectedYear;
            const startOfMonth = new Date(year, i, 1);
            const endOfMonth = new Date(year, i + 1, 1);

            return { start: startOfMonth, end: endOfMonth };
        });

        return months;
    }

    generateSeries() {
        let series: any[] = [];
        let name: string = "Dana CSR";
        let data: any[] = [];

        this.monthsToArray.map((month: any) => {
            let count: number = 0;
            let total: number = 0;

            this.funds.map((fund: any) => {
                const fundDate = new Date(fund.created_at);
                if (fundDate > month.start && fundDate < month.end)
                    count++, (total += fund.nominal);
            });

            data.push({
                x: new Date(month.start.getTime() / 1000).getTime() * 1000,
                y: total,
            });
        });
        series.push({ name, data });

        this.series = series;
    }

    generateDonutSeries() {
        const planFund: any = this.statistics.filter((statistic: any) => {
            return statistic.title === "Rencana Dana CSR";
        });

        const usedFund: any = this.statistics.filter((statistic: any) => {
            return statistic.title === "Dana Yang Telah Diberikan";
        });

        if (!planFund.length || !usedFund.length) return;

        this.donutSeries = [
            planFund[0].value - usedFund[0].value,
            usedFund[0].value,
        ];
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    async mounted() {
        setTimeout(() => {
            this.generateSeries();
            this.generateDonutSeries();
            this.isChartLoaded = true;
        }, 800);
    }
}
